<template>
  <g id="g4619">
    <path
      d="m 7746,1991.5 c 0,21.8153 -17.6846,39.5 -39.5,39.5 -21.8154,0 -39.5,-17.6847 -39.5,-39.5 0,-21.8153 17.6846,-39.5 39.5,-39.5 21.8154,0 39.5,17.6847 39.5,39.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path795"/><path
      d="m 8667,11449.5 c 0,17.949 -14.5508,32.5 -32.5,32.5 -17.9492,0 -32.5,-14.551 -32.5,-32.5 0,-17.949 14.5508,-32.5 32.5,-32.5 17.9492,0 32.5,14.551 32.5,32.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path797"/><path
      d="m 8780,11449.5 c 0,17.949 -14.5508,32.5 -32.5,32.5 -17.9492,0 -32.5,-14.551 -32.5,-32.5 0,-17.949 14.5508,-32.5 32.5,-32.5 17.9492,0 32.5,14.551 32.5,32.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path799"/><path
      d="m 3210,12163.5 c 0,83.671 -67.8289,151.5 -151.5,151.5 -83.6711,0 -151.5,-67.829 -151.5,-151.5 0,-83.671 67.8289,-151.5 151.5,-151.5 83.6711,0 151.5,67.829 151.5,151.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path801"/><path
      d="m 3134,12163.5 c 0,41.697 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.803 -75.5,-75.5 0,-41.697 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.803 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path803"/><path
      d="m 3702,11445.5 c 0,104.105 -84.842,188.5 -189.5,188.5 -104.658,0 -189.5,-84.395 -189.5,-188.5 0,-104.105 84.842,-188.5 189.5,-188.5 104.658,0 189.5,84.395 189.5,188.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path805"/><path
      d="m 3569,11445.5 c 0,31.204 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.296 -56.5,-56.5 0,-31.204 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.296 56.5,56.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path807"/><path
      d="m 3531,1938.5 c 0,31.2041 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.2959 -56.5,-56.5 0,-31.2041 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.2959 56.5,56.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path809"/><path
      d="m 3512,1938.5 c 0,20.7107 -16.7893,37.5 -37.5,37.5 -20.7107,0 -37.5,-16.7893 -37.5,-37.5 0,-20.7107 16.7893,-37.5 37.5,-37.5 20.7107,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path811"/><path
      d="m 2662,1938.5 c 0,31.2041 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.2959 -56.5,-56.5 0,-31.2041 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.2959 56.5,56.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path813"/><path
      d="m 2643,1938.5 c 0,20.7107 -16.7893,37.5 -37.5,37.5 -20.7107,0 -37.5,-16.7893 -37.5,-37.5 0,-20.7107 16.7893,-37.5 37.5,-37.5 20.7107,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path815"/><path
      d="m 2643,2713.5 c 0,62.6843 -50.8157,113.5 -113.5,113.5 -62.6843,0 -113.5,-50.8157 -113.5,-113.5 0,-62.6843 50.8157,-113.5 113.5,-113.5 62.6843,0 113.5,50.8157 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path817"/><path
      d="m 2479,2713.5 c 0,-27.8904 22.6096,-50.5 50.5,-50.5 27.8904,0 50.5,22.6096 50.5,50.5 0,27.8904 -22.6096,50.5 -50.5,50.5 -27.8904,0 -50.5,-22.6096 -50.5,-50.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path819"/><path
      d="m 9369,11199.5 c 0,123.988 -100.5117,224.5 -224.5,224.5 -123.9883,0 -224.5,-100.512 -224.5,-224.5 0,-123.988 100.5117,-224.5 224.5,-224.5 123.9883,0 224.5,100.512 224.5,224.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path821"/><path
      d="m 9390,11199.5 c 0,135.586 -109.9141,245.5 -245.5,245.5 -135.5859,0 -245.5,-109.914 -245.5,-245.5 0,-135.586 109.9141,-245.5 245.5,-245.5 135.5859,0 245.5,109.914 245.5,245.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path823"/><path
      d="m 9369,2878.5 c 0,123.988 -100.5117,224.5 -224.5,224.5 -123.9883,0 -224.5,-100.512 -224.5,-224.5 0,-123.988 100.5117,-224.5 224.5,-224.5 123.9883,0 224.5,100.512 224.5,224.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path825"/><path
      d="m 9390,2878.5 c 0,135.5859 -109.9141,245.5 -245.5,245.5 -135.5859,0 -245.5,-109.9141 -245.5,-245.5 0,-135.5859 109.9141,-245.5 245.5,-245.5 135.5859,0 245.5,109.9141 245.5,245.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path827"/><path
      d="m 3170,11199.5 c 0,123.988 -100.512,224.5 -224.5,224.5 -123.988,0 -224.5,-100.512 -224.5,-224.5 0,-123.988 100.512,-224.5 224.5,-224.5 123.988,0 224.5,100.512 224.5,224.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path829"/><path
      d="m 3191,11199.5 c 0,135.586 -109.9141,245.5 -245.5,245.5 -135.5859,0 -245.5,-109.914 -245.5,-245.5 0,-135.586 109.9141,-245.5 245.5,-245.5 135.5859,0 245.5,109.914 245.5,245.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path831"/><path
      d="m 3170,2878.5 c 0,123.988 -100.512,224.5 -224.5,224.5 -123.988,0 -224.5,-100.512 -224.5,-224.5 0,-123.988 100.512,-224.5 224.5,-224.5 123.988,0 224.5,100.512 224.5,224.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path833"/><path
      d="m 3191,2878.5 c 0,135.5859 -109.9141,245.5 -245.5,245.5 -135.5859,0 -245.5,-109.9141 -245.5,-245.5 0,-135.5859 109.9141,-245.5 245.5,-245.5 135.5859,0 245.5,109.9141 245.5,245.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path835"/><path
      d="m 3231,7401.5 c 0,157.6772 -127.8227,285.5 -285.5,285.5 -157.6772,0 -285.5,-127.8228 -285.5,-285.5 0,-157.6773 127.8228,-285.5 285.5,-285.5 157.6773,0 285.5,127.8227 285.5,285.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path837"/><path
      d="m 3210,7401.5 c 0,146.0791 -118.4206,264.5 -264.5,264.5 -146.0793,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4207,-264.5 264.5,-264.5 146.0794,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path839"/><path
      d="m 9430,7401.5 c 0,157.6772 -127.8223,285.5 -285.5,285.5 -157.6777,0 -285.5,-127.8228 -285.5,-285.5 0,-157.6773 127.8223,-285.5 285.5,-285.5 157.6777,0 285.5,127.8227 285.5,285.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path841"/><path
      d="m 9409,7401.5 c 0,146.0791 -118.4209,264.5 -264.5,264.5 -146.0791,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4209,-264.5 264.5,-264.5 146.0791,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path843"/><path
      d="m 2946,10708.5 c 0,83.671 -67.8289,151.5 -151.5,151.5 -83.6711,0 -151.5,-67.829 -151.5,-151.5 0,-83.671 67.8289,-151.5 151.5,-151.5 83.6711,0 151.5,67.829 151.5,151.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path845"/><path
      d="m 2870,10708.5 c 0,41.697 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.803 -75.5,-75.5 0,-41.697 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.803 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path847"/><path
      d="m 2946,3375.5 c 0,83.6711 -67.8289,151.5 -151.5,151.5 -83.6711,0 -151.5,-67.8289 -151.5,-151.5 0,-83.6711 67.8289,-151.5 151.5,-151.5 83.6711,0 151.5,67.8289 151.5,151.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path849"/><path
      d="m 2870,3375.5 c 0,41.6975 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8025 -75.5,-75.5 0,-41.6975 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8025 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path851"/><path
      d="m 9447,3375.5 c 0,83.6711 -67.8291,151.5 -151.5,151.5 -83.6709,0 -151.5,-67.8289 -151.5,-151.5 0,-83.6711 67.8291,-151.5 151.5,-151.5 83.6709,0 151.5,67.8289 151.5,151.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path853"/><path
      d="m 9371,3375.5 c 0,41.6975 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8025 -75.5,-75.5 0,-41.6975 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8025 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path855"/><path
      d="m 9447,10708.5 c 0,83.671 -67.8291,151.5 -151.5,151.5 -83.6709,0 -151.5,-67.829 -151.5,-151.5 0,-83.671 67.8291,-151.5 151.5,-151.5 83.6709,0 151.5,67.829 151.5,151.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path857"/><path
      d="m 9371,10708.5 c 0,41.697 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.803 -75.5,-75.5 0,-41.697 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.803 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path859"/><path
      d="m 2830,10292.5 c 0,-63.789 51.7112,-115.5 115.5,-115.5 63.7888,0 115.5,51.711 115.5,115.5 0,63.789 -51.7112,115.5 -115.5,115.5 -63.7888,0 -115.5,-51.711 -115.5,-115.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path861"/><path
      d="m 9029,3791.5 c 0,-63.7888 51.7109,-115.5 115.5,-115.5 63.7891,0 115.5,51.7112 115.5,115.5 0,63.7888 -51.7109,115.5 -115.5,115.5 -63.7891,0 -115.5,-51.7112 -115.5,-115.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path863"/><path
      d="m 9029,10292.5 c 0,-63.789 51.7109,-115.5 115.5,-115.5 63.7891,0 115.5,51.711 115.5,115.5 0,63.789 -51.7109,115.5 -115.5,115.5 -63.7891,0 -115.5,-51.711 -115.5,-115.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path865"/><path
      d="m 2830,3791.5 c 0,-63.7888 51.7112,-115.5 115.5,-115.5 63.7888,0 115.5,51.7112 115.5,115.5 0,63.7888 -51.7112,115.5 -115.5,115.5 -63.7888,0 -115.5,-51.7112 -115.5,-115.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path867"/><path
      d="m 3021,6720.5 c 0,41.6973 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8027 -75.5,-75.5 0,-41.6973 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8027 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path869"/><path
      d="m 9220,6720.5 c 0,41.6973 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8027 -75.5,-75.5 0,-41.6973 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8027 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path871"/><path
      d="M 2567,1413 H 5279"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path873"/><path
      d="m 5279.5,1413 c 62.6841,0 113.5,50.8157 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path875"/><path
      d="m 5392,1617 v -91"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path877"/><path
      d="m 5506.5,1731 c -62.6841,0 -113.5,-50.8157 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path879"/><path
      d="M 6583,1731 H 5506"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path881"/><path
      d="m 6697,1617.5 c 0,62.6843 -50.8159,113.5 -113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path883"/><path
      d="m 6697,1526 v 91"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path885"/><path
      d="m 6697,1526.5 c 0,-62.6843 50.8159,-113.5 113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path887"/><path
      d="M 6810,1413 H 9522"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path889"/><path
      d="m 9522.5,1414 c 125.0928,0 226.5,101.4075 226.5,226.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path891"/><path
      d="M 9749,1640 V 12390"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path893"/><path
      d="m 9749,12390.5 c 0,125.093 -101.4072,226.5 -226.5,226.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path895"/><path
      d="M 9522,12617 H 2567"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path897"/><path
      d="m 2567.5,12617 c -125.0925,0 -226.5,-101.407 -226.5,-226.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path899"/><path
      d="M 2340,12390 V 1640"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path901"/><path
      d="m 2341,1640.5 c 0,-125.0925 101.4075,-226.5 226.5,-226.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path903"/><path
      d="m 7704,1715 h 4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path905"/><path
      d="m 7667,1753.5 c 0,-20.7107 16.7895,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path907"/><path
      d="M 7666,1889 V 1753"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path909"/><path
      d="m 7704.5,1927 c -20.7105,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path911"/><path
      d="m 7708,1927 h -4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path913"/><path
      d="m 7746,1889.5 c 0,20.7107 -16.7896,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path915"/><path
      d="m 7746,1753 v 136"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path917"/><path
      d="m 7708.5,1716 c 20.7104,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path919"/><path
      d="m 8577,1634 v 255"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path921"/><path
      d="m 8539.5,1597 c 20.7109,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path923"/><path
      d="m 8275,1596 h 264"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path925"/><path
      d="m 8238,1634.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path927"/><path
      d="m 8237,1638 v -4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path929"/><path
      d="m 8275.5,1676 c -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path931"/><path
      d="m 8422,1795 h -121 v 53 h 197 v -172 h -223"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path933"/><path
      d="m 8460,1757.5 c 0,20.7107 -16.7891,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path935"/><path
      d="m 8460,1753 v 4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path937"/><path
      d="m 8422.5,1716 c 20.7109,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path939"/><path
      d="m 8260,1715 h 162"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path941"/><path
      d="m 8223,1753.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path943"/><path
      d="M 8222,1889 V 1753"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path945"/><path
      d="m 8260.5,1927 c -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path947"/><path
      d="M 8539,1927 H 8260"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path949"/><path
      d="m 8577,1889.5 c 0,20.7107 -16.7891,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path951"/><path
      d="m 8675,1715 h 280"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path953"/><path
      d="m 8638,1753.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path955"/><path
      d="M 8638,1889 V 1753"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path957"/><path
      d="m 8675.5,1927 c -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path959"/><path
      d="m 8679,1927 h -4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path961"/><path
      d="m 8717,1889.5 c 0,20.7107 -16.7891,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path963"/><path
      d="m 8914,1889 v -94 h -197 v 94"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path965"/><path
      d="m 8951.5,1927 c -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path967"/><path
      d="m 8955,1927 h -4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path969"/><path
      d="m 8993,1889.5 c 0,20.7107 -16.7891,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path971"/><path
      d="m 8993,1753 v 136"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path973"/><path
      d="m 8955.5,1716 c 20.7109,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path975"/><path
      d="m 9367,1715 h 4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path977"/><path
      d="m 9330,1753.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path979"/><path
      d="m 9133,1753 v 95 h 196 v -95"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path981"/><path
      d="m 9095.5,1716 c 20.7109,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path983"/><path
      d="m 9091,1715 h 4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path985"/><path
      d="m 9054,1753.5 c 0,-20.7107 16.7891,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path987"/><path
      d="M 9053,1889 V 1753"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path989"/><path
      d="m 9091.5,1927 c -20.7109,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path991"/><path
      d="M 9371,1927 H 9091"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path993"/><path
      d="m 9409,1889.5 c 0,20.7107 -16.7891,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path995"/><path
      d="m 9409,1753 v 136"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path997"/><path
      d="m 9371.5,1716 c 20.7109,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path999"/><path
      d="m 7885,1753 v 95 h 197 v -95"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1001"/><path
      d="m 7848.5,1716 c 20.7104,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1003"/><path
      d="m 7844,1715 h 4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1005"/><path
      d="m 7807,1753.5 c 0,-20.7107 16.7895,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1007"/><path
      d="M 7806,1889 V 1753"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1009"/><path
      d="m 7844.5,1927 c -20.7105,0 -37.5,-16.7893 -37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1011"/><path
      d="M 8124,1927 H 7844"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1013"/><path
      d="m 8162,1889.5 c 0,20.7107 -16.7896,37.5 -37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1015"/><path
      d="m 8161,1753 v 136"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1017"/><path
      d="m 8124.5,1716 c 20.7104,0 37.5,16.7893 37.5,37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1019"/><path
      d="m 8120,1715 h 4"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1021"/><path
      d="m 8083,1753.5 c 0,-20.7107 16.7895,-37.5 37.5,-37.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1023"/><path
      d="m 2832,3791.5 c 0,-62.6843 50.8157,-113.5 113.5,-113.5 62.6843,0 113.5,50.8157 113.5,113.5 0,62.6843 -50.8157,113.5 -113.5,113.5 -62.6843,0 -113.5,-50.8157 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1025"/><path
      d="m 2883,3791.5 c 0,-34.5178 27.9822,-62.5 62.5,-62.5 34.5178,0 62.5,27.9822 62.5,62.5 0,34.5178 -27.9822,62.5 -62.5,62.5 -34.5178,0 -62.5,-27.9822 -62.5,-62.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1027"/><path
      d="m 2832,10292.5 c 0,-62.685 50.8157,-113.5 113.5,-113.5 62.6843,0 113.5,50.815 113.5,113.5 0,62.685 -50.8157,113.5 -113.5,113.5 -62.6843,0 -113.5,-50.815 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1029"/><path
      d="m 2883,10292.5 c 0,-34.518 27.9822,-62.5 62.5,-62.5 34.5178,0 62.5,27.982 62.5,62.5 0,34.518 -27.9822,62.5 -62.5,62.5 -34.5178,0 -62.5,-27.982 -62.5,-62.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1031"/><path
      d="m 9031,3791.5 c 0,-62.6843 50.8154,-113.5 113.5,-113.5 62.6846,0 113.5,50.8157 113.5,113.5 0,62.6843 -50.8154,113.5 -113.5,113.5 -62.6846,0 -113.5,-50.8157 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1033"/><path
      d="m 9082,3791.5 c 0,-34.5178 27.9824,-62.5 62.5,-62.5 34.5176,0 62.5,27.9822 62.5,62.5 0,34.5178 -27.9824,62.5 -62.5,62.5 -34.5176,0 -62.5,-27.9822 -62.5,-62.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1035"/><path
      d="m 9031,10292.5 c 0,-62.685 50.8154,-113.5 113.5,-113.5 62.6846,0 113.5,50.815 113.5,113.5 0,62.685 -50.8154,113.5 -113.5,113.5 -62.6846,0 -113.5,-50.815 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1037"/><path
      d="m 9082,10292.5 c 0,-34.518 27.9824,-62.5 62.5,-62.5 34.5176,0 62.5,27.982 62.5,62.5 0,34.518 -27.9824,62.5 -62.5,62.5 -34.5176,0 -62.5,-27.982 -62.5,-62.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1039"/><path
      d="m 2832,3791.5 c 0,-62.6843 50.8157,-113.5 113.5,-113.5 62.6843,0 113.5,50.8157 113.5,113.5 0,62.6843 -50.8157,113.5 -113.5,113.5 -62.6843,0 -113.5,-50.8157 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1041"/><path
      d="m 2883,3791.5 c 0,-34.5178 27.9822,-62.5 62.5,-62.5 34.5178,0 62.5,27.9822 62.5,62.5 0,34.5178 -27.9822,62.5 -62.5,62.5 -34.5178,0 -62.5,-27.9822 -62.5,-62.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path1043"
  /></g>
</template>
<script>
export default {
  name: "IngunMa11Top",
};
</script>
